<template>
  <div class="mt-3">
    <b-container>
      <b-row class="justify-content-center">
        <b-col md="8">
          <b-card>
            <div class="text-center mb-1">
              <b-link
                class="brand-logo"
                to="/"
              >
                <GoZayaanBrandLogo />
              </b-link>
            </div>
            <h4 class="font-weight-bolder mb-1">
              Traveller Details
            </h4>
            <traveller-form :traveller-detail="travellerInfo" />
            <div class="btn-wrapper text-center border-top pt-1 d-flex flex-nowrap">
              <b-button
                to="/"
                variant="outline-primary"
                class="mr-1 w-100"
              >
                Skip
              </b-button>
              <b-button
                variant="primary"
                class="w-100"
                :disabled="isBtnLoading"
                @click="updateData"
              >
                <b-spinner
                  v-if="isBtnLoading"
                  small
                />
                Save
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import TravellerForm from '@/components/common/TravellerForm.vue'
import GoZayaanBrandLogo from '@/components/icons/GoZayaanBrandLogo.vue'
import api from '@/libs/api'

export default {
  name: 'EmployeeProfile',
  components: { TravellerForm, GoZayaanBrandLogo },
  data() {
    return {
      travellerInfo: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        gender: '',
        code: '',
        prefix: '',
        date_of_birth: '',
        passport_expiry_date: null,
        passport_country: 'BD',
      },
      isBtnLoading: false,
    }
  },
  mounted() {
    this.getTravelerInfo()
  },
  methods: {
    getTravelerInfo() {
      api.getData('account_management/traveller_account/', true).then(response => {
        // eslint-disable-next-line prefer-destructuring
        this.travellerInfo = response.data.result.filter(traveller => traveller.own_account === true)[0]
      }).catch()
    },
    updateData() {
      this.isBtnLoading = true
      api.updateData(`account_management/traveller_account/${this.travellerInfo.traveller_id}`, true, this.travellerInfo)
        .then(response => {
          if (response.data.status) {
            this.$router.push('/')
          }
        }).catch().finally(() => {
          this.isBtnLoading = false
        })
    },
  },
}
</script>

<style scoped>

</style>
