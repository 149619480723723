<template>
  <b-row>
    <template v-if="showBasicInfo">
      <b-col md="6">
        <b-form-group
          label="First Name"
          label-for="first-name"
        >
          <b-form-input
            id="first_name"
            v-model="travellerDetail.first_name"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Last Name"
          label-for="last-name"
        >
          <b-form-input
            id="last-name"
            v-model="travellerDetail.last_name"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Email"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="travellerDetail.email"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Phone"
          label-for="phone"
        >
          <b-input-group prepend="+880">
            <b-form-input
              id="phone"
              v-model="travellerDetail.phone"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </template>
    <b-col md="6">
      <b-form-group
        label="Gender"
        label-for="gender"
      >
        <v-select
          id="gender"
          v-model="travellerDetail.gender"
          :options="[ {value: 'F', label: 'Female'}, {value: 'M', label: 'Male'} ]"
          :reduce="option => option.value"
          class="status-filter-select"
          placeholder="Select gender"
          @input="updatePrefix"
        />
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        label="Title"
        label-for="Title"
      >
        <v-select
          id="title"
          v-model="travellerDetail.prefix"
          :options="userCode"
          :reduce="option => option.value"
          class="status-filter-select"
          placeholder="Select Prefix"
        />
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        label="Code"
        label-for="code"
      >
        <v-select
          id="code"
          v-model="travellerDetail.code"
          :options="[ {value: 'ADT', label: 'Adult'} ]"
          :reduce="option => option.value"
          class="status-filter-select"
          placeholder="Select Code"
        />
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        label="Birth Date"
        label-for="birth-date"
      >
        <b-form-datepicker
          id="birth-date"
          v-model="travellerDetail.date_of_birth"
          reset-button
        />
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        label="Passport Number"
        label-for="passport-number"
      >
        <b-form-input
          id="passport-number"
          v-model="travellerDetail.passport_number"
        />
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        label="Passport Expiry Date"
        label-for="passport-expiry"
      >
        <b-form-datepicker
          id="pasport-expiry"
          v-model="travellerDetail.passport_expiry_date"
          reset-button
        />
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        label="Passport Country"
        label-for="passport-country"
      >
        <b-form-input
          id="passport-country"
          v-model="travellerDetail.passport_country"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: 'TravellerForm',
  props: {
    travellerDetail: {
      type: Object,
      required: true,
    },
    showBasicInfo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      userCode: [
        { label: 'Mr', value: 'Mr' },
        { label: 'Ms', value: 'Ms' },
        { label: 'Mrs', value: 'Mrs' },
      ],
    }
  },
  methods: {
    updatePrefix(val) {
      if (val === 'M') {
        this.travellerDetail.prefix = 'Mr'
      } else {
        this.travellerDetail.prefix = 'Ms'
      }
    },
  },
}
</script>

<style scoped>

</style>
